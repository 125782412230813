var Demo = (function() {

	function output(node) {
		var existing = $('#result .croppie-result');
		if (existing.length > 0) {
			existing[0].parentNode.replaceChild(node, existing[0]);
		}
		else {
			$('#result')[0].appendChild(node);
		}
	}

	function popupResult(result) {
		var html;
		if (result.html) {
			html = result.html;
		}
		if (result.src) {
			html = '<img src="' + result.src + '" />';
		}
		swal({
			title: '',
			html: true,
			text: html,
			allowOutsideClick: true
		});
		setTimeout(function(){
			$('.sweet-alert').css('margin', function() {
				var top = -1 * ($(this).height() / 2),
					left = -1 * ($(this).width() / 2);

				return top + 'px 0 0 ' + left + 'px';
			});
		}, 1);
	}
	function demoUpload() {
		var $uploadCrop;

		function readFile(input) {
			
		//	console.log("readFile")
			var profilepic=input.files[0].name
			
			sessionStorage.setItem("profilepic",profilepic) 
			// sessionStorage.setItem("resultbutton",'0')
 			if (input.files && input.files[0]) {
	            var reader = new FileReader();
	            
	            reader.onload = function (e) {
					$('.upload-demo').addClass('ready');
	            	$uploadCrop.croppie('bind', {
	            		url: e.target.result
	            	}).then(function(){
	            		//console.log('jQuery bind complete');
	            	});
	            	
	            };
	            
	            reader.readAsDataURL(input.files[0]);
	        }
	        else {
		        swal("Sorry - you're browser doesn't support the FileReader API");
		    }
		}

		$uploadCrop = $('#upload-demo').croppie({
			viewport: {
				width: 200,
				height: 200,
				type: 'circle'
			},
			enableExif: true,
			enableOrientation: true,
			showZoomer: false,
		});

		$('#upload').on('change', function () { readFile(this); });
				$('.upload-result').on('click', function (ev) {
				//	console.log(sessionStorage.getItem("profilepic"));
					if(sessionStorage.getItem("profilepic")==null){
						//alert("error");
						// sessionStorage.setItem("resultbutton",'0')
					}else{
			$uploadCrop.croppie('result', {
				type: 'canvas',
				size: 'viewport'
			}).then(function (resp) {
				popupResult({
					src: resp
				});
			});
		}
		});
		
	
		
		//NOTE for you: Rotate
        $( "#rotateLeft" ).click(function() {
            $uploadCrop.croppie('rotate', parseInt($(this).data('rotate')));
        });
       
        $( "#rotateRight" ).click(function() {
            $uploadCrop.croppie('rotate',parseInt($(this).data('rotate')));
        });
	}


	function bindNavigation () {
		var $html = $('html');
		$('nav a').on('click', function (ev) {
			var lnk = $(ev.currentTarget),
				href = lnk.attr('href'),
				targetTop = $('a[name=' + href.substring(1) + ']').offset().top;

			$html.animate({ scrollTop: targetTop });
			ev.preventDefault();
		});
	}

	function init() {
		bindNavigation();
		demoUpload();
	}

	return {
		init: init
	};
})();

